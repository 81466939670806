exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-propos-js": () => import("./../../../src/pages/a-propos.js" /* webpackChunkName: "component---src-pages-a-propos-js" */),
  "component---src-pages-conditions-service-js": () => import("./../../../src/pages/conditions-service.js" /* webpackChunkName: "component---src-pages-conditions-service-js" */),
  "component---src-pages-conditions-vente-js": () => import("./../../../src/pages/conditions-vente.js" /* webpackChunkName: "component---src-pages-conditions-vente-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-hebergement-web-js": () => import("./../../../src/pages/hebergement-web.js" /* webpackChunkName: "component---src-pages-hebergement-web-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mentions-legales-js": () => import("./../../../src/pages/mentions-legales.js" /* webpackChunkName: "component---src-pages-mentions-legales-js" */),
  "component---src-pages-protection-donnees-personnelles-js": () => import("./../../../src/pages/protection-donnees-personnelles.js" /* webpackChunkName: "component---src-pages-protection-donnees-personnelles-js" */),
  "component---src-pages-serveur-minecraft-js": () => import("./../../../src/pages/serveur-minecraft.js" /* webpackChunkName: "component---src-pages-serveur-minecraft-js" */),
  "component---src-pages-serveur-rust-js": () => import("./../../../src/pages/serveur-rust.js" /* webpackChunkName: "component---src-pages-serveur-rust-js" */),
  "component---src-pages-serveurs-jeux-js": () => import("./../../../src/pages/serveurs-jeux.js" /* webpackChunkName: "component---src-pages-serveurs-jeux-js" */),
  "component---src-pages-service-details-js": () => import("./../../../src/pages/service-details.js" /* webpackChunkName: "component---src-pages-service-details-js" */)
}

